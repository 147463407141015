<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.xxljob.task.title.editHeadTitle') :  $t('cip.plat.xxljob.task.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>

let baseUrl = '/api/sinoma-system/dict-biz/dictionary?code='
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getDetail} from "@/api/system/adInfo";


export default {
  name: "adInfoEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.xxljob.task.username'),
          prop: 'username',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.xxljob.task.username`),
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.username'),
              trigger: "blur"
            },
          ],
        },
        //   处理的 多一个字段 暂留
        // {
        //   label: this.$t('cip.plat.xxljob.task.adType'),
        //   prop: 'adType',
        //   labelWidth: 130,
        //   span: 8,
        //   type: 'select',
        //   dataType: "string",
        //   disabled: !['edit','add'].includes(this.type),
        //
        //   dicUrl: `${baseUrl}AD_INFO_TYPE`,
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        //   value:'1001',
        //
        // },
        {
          label: this.$t('cip.plat.xxljob.task.password'),
          prop: 'text',
          formslot: true,
          labelWidth: 130,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t(`cip.plat.xxljob.task.password`),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.password'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.role'),
          prop: 'role',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.xxljob.task.role`),
          readonly: !['add', 'edit'].includes(this.type),
          control: (val) => {
            if (val === '1002') {
              return {
                permission: {
                  display: false
                },
              }
            } else {
              return {
                permission: {
                  display: true
                },
              }
            }
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.xxljob.task.role'),
              trigger: "blur"
            },
          ],
        },
        {
          label: this.$t('cip.plat.xxljob.task.permission'),
          prop: 'permission',
          labelWidth: 130,
          span: 8,
          maxlength: 50,
          placeholder: this.$t(`cip.plat.xxljob.task.permission`),
          readonly: !['add', 'edit'].includes(this.type),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
